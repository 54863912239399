<template>
    <div class="userInfo">
        <div v-wechat-title="$route.meta.title"></div>
        <img src="../static/image/myAward/address_background@2x.png" class="BJ">
        
        <div class="userInfoContent">
            <van-address-edit
                :area-list="areaList"
                :area-columns-placeholder="['请选择', '请选择', '请选择']"
                @save="onSave"
                :show-delete="false"
                :show-postal="false"
                :show-set-default="false"
                :show-search-result="false"
                save-button-text="提交"
                area-placeholder="点击选择所在地区"
                :detail-rows="3"
                :tel-maxlength="11"
                detail-maxlength="48"
            />
        </div>
        <div class="alertMaster" v-show="isShow">
            <div class="alert">
                <div class="alertTitle">请确认个人信息并提交</div>
                <div class="deco"></div>
                <div class="alertInfo">
                    <div class="usernameWrap">
                        <div class="username">姓名:</div>
                        <div class="usernameInfo">{{user_name}}</div>
                    </div>
                    
                    <div class="phoneWrap">
                        <div class="phone">联系电话:</div>
                        <div class="phoneInfo">{{tel}}</div>
                    </div>
                    <div class="IDWrap">
                        <div class="ID">所在地区</div>
                        <div class="IDinfo">{{area}}</div>
                    </div>
                    <div class="addressWrap">
                        <div class="address">收货地址:</div>
                        <div class="addressInfo">{{address}}</div>
                    </div>
                </div>
                <div class="alertDesc">
                    请如实填写以上信息，并保持所填写手机号畅通，我们会尽快给您寄送奖
                </div>
                <div class="alertBtn">
                    <button class="return" @click="close">返回编辑</button>
                    <button class="confirm" @click="commit">确认提交</button>
                </div>
            </div>
        </div>
        <div class="userInfoDesc">
            请如实填写以上信息，并保持所填写手机号畅通，我们会尽快给您寄送奖
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { Area, Popup, AddressEdit, Toast} from 'vant';

Vue.use(Popup);
Vue.use(Area);
Vue.use(AddressEdit);

export default {
  data() {
    return {
        show: false,
        areaList:areaList,
        isShow:false,
        user_name:'',
        tel:'',
        area:'',
        address:'',
        app_key: "3ab58649236688fd",
        secret_key: "0ebadf1ad6c24625a46d3d02d11730f7",
        date: String(new Date().valueOf()).slice(0, 10),
        prize_id:0
    };
  },
   mounted(){
      //获取userId、token、prize_id
        this.userId = this.getRequest().userId ? this.getRequest().userId : "";
        this.token = this.getRequest().token ? this.getRequest().token : "";
        this.prize_id = this.getRequest().prize_id ? this.getRequest().prize_id : "";
        
  },
    methods: {
        showPopup() {
            this.show = true;
        },
        onSave(content) {
            let {addressDetail,city,country,county,name,tel} = content
            this.isShow = true
            this.user_name = name
            this.tel = tel
            this.area = city + country + county
            this.address = addressDetail
            
        },
        close(){
            this.isShow = false
        },
        //格式化URL
        getRequest: () => {
            var url = window.location.search; //获取url中"?"符后的字串
            var theRequest = new Object();
            if (url.indexOf("?") != -1) {
                var str = url.substr(1);
                var strs = str.split("&");
                for (var i = 0; i < strs.length; i++) {
                    theRequest[strs[i].split("=")[0]] = decodeURI(
                        strs[i].split("=")[1]
                    );
                }
            }

            return theRequest;
        },
        //获取签名
        getSign: (app_key, date, URL, secret_key) => {
            var sign ="app_key=" +app_key +"&" +"timestamp=" +date +"&" +"uri=" + URL +"&" +"secret_key=" +secret_key;
            return sign;
        },
        async commit(){
            //获取签名
            let sign = this.$md5(this.getSign(this.app_key,this.date,"v1/BlessBag/saveAddress",this.secret_key));
            let {errNo,message,data} = await this.$api.commitUserInfo(this.userId,5,this.tel,this.user_name,this.area,this.address)
            if(errNo == 0){
                Toast(data.msg)
            }else{
                Toast(message)
            }
            this.isShow = false
            this.$router.push(`/Index?userId=${this.userId}&token=${this.token}`)
        }
  },
};
</script>
<style scoped>
body{
   background-color: #fe8a42;
}
.BJ{
    width: 100%;
    height: 680px;
}
/deep/.van-field__label{
    margin-right: 10px;
}
/deep/.van-address-edit__buttons{
    padding: 0;
    margin-top: 76px;
}
/deep/.van-button__text{
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FF1400;
    letter-spacing: 2px;
    font-size: 18px;
}
/deep/.van-button--danger{
    background: #FFEF76;
    border: none;
}
/deep/.van-cell__title {
    width: 65px;
    line-height: 40px;
}
/deep/.van-address-edit__fields {
    width: 335px;
    height: 280px;
    margin: 0 auto;
    background-color: white;
    padding: 0 15px;
    box-sizing: border-box;
}
/deep/.van-icon {
    position: relative;
    right: 30px;
}
/deep/.van-cell::after {
    border: none;
}
/deep/.van-cell {
    padding: 10px 0 0 0;
}
.userInfo {
    background: url("../static/image/myAward/address_background@2x.png")
        no-repeat;
    /* background-size: 100%; */
    width: 375px;
    margin: 0 auto;
    background-color: #FE8A42;
    position: relative;
    overflow-y: hidden;
}
.userInfoDesc{
    position: absolute;
    width: 270px;
    height: 34px;
    text-align: center;
    top: 442px;
    left: 52px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    padding-top: 10px;
}
.userInfoContent {
    position: absolute;
    top: 141px;
    left: 8px;
}
.content {
    position: absolute;
    width: 335px;
    height: 280px;
    top: 141px;
    right: 21px;
    background: rgba(255, 255, 255, 1);
    border-radius: 12px;
    padding: 20px 15px;
    box-sizing: border-box;
}
.usernameWrap,
.areaWrap,
.phoneWrap {
    display: flex;
    margin-top: 10px;
    height: 40px;
    line-height: 40px;
}
.addressWrap {
    display: flex;
    margin-top: 10px;
    height: 40px;
}
.usernameWrap {
    margin-top: 0;
}
.username,
.ID,
.phone,
.address {
    width: 55px;
    height: 20px;
    font-size: 0.28rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(32, 32, 35, 1);
    margin-right: 10px;
}
.address {
    margin-top: 10px;
}
.alertMaster{
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9;
    top: 0;
}
.alert{
    position: absolute;
    width:300px;
    height:429px;
    top: 87px;
    left: 38px;
    background:rgba(255,255,255,1);
    border-radius:8px;
    padding: 20px;
    box-sizing: border-box;
}
.alertTitle{
    width:  160px;
    height:22px;
    font-size:16px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(32,32,35,1);
    margin: 5px auto 0;
    text-align: center;
}
.deco{
    width:189px;
    height:14px;
    background: rgba(245, 87, 75, 0.3);
    margin: -14px auto 0;
}
.alertInfo{
    margin-top: 30px;
}
.alert .usernameWrap, .alert .IDWrap, .alert .phoneWrap, .alert .addressWrap{
    display: flex;
    margin-top: 15px;
    height: 22px;
    line-height: 22px;
}
.alertInfo .username, .alertInfo .ID, .alertInfo .phone, .alertInfo .address{
    width: 80px;
    height: 20px;
    font-size:16px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(153,153,153,1);
}
.usernameInfo ,.IDinfo ,.phoneInfo, .addressInfo{
    width:170px;
    font-size:16px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(32,32,35,1);
    overflow:hidden;
    word-break: break-all;
}
.addressInfo{
    height:88px;
    margin-top: 11px;
}
.alertDesc{
    width:265px;
    height:34px;
    margin-top: 100px;
    font-size:12px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(245,87,75,1);
    text-align: center;
}
.alertDesc span:nth-child(2){
    color: #999;
}
.alertBtn{
    border: none;
    display: flex;
    margin-top: 22px;
    justify-content: space-between;
}
.return{
    width:110px;
    height:34px;
    border-radius:18px;
    border: 1px solid #FF5000;
    font-size:14px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color: #F5574B;
    background-color: white;
    text-align: center;
}
.confirm{
    width:110px;
    height:34px;
    border-radius:18px;
    border: 1px solid #FF5000;
    font-size:14px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(255,255,255,1);
    background: #F5574B;
    text-align: center;
}
/deep/input {
    width: 227px;
    height: 40px;
    background: rgba(255, 80, 0, 0.04);
    border-radius: 2px;
    border: 1px solid rgba(255, 80, 0, 0.1);
    font-size: 14px;
    padding: 0 0 0 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff5000;
}
/deep/textarea {
    width: 227px;
    border: 1px solid rgba(255, 80, 0, 0.1);
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff5000;
    padding: 0 0 0 10px;
    background: rgba(255, 80, 0, 0.04);
}
/deep/input::-webkit-input-placeholder {
    /*WebKit browsers*/
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 80, 0, 0.5);
}
/deep/input::-moz-input-placeholder {
    /*Mozilla Firefox*/
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 80, 0, 0.5);
}
/deep/input::-ms-input-placeholder {
    /*Internet Explorer*/
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 80, 0, 0.5);
}
/deep/textarea::-webkit-input-placeholder {
    /*WebKit browsers*/
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 80, 0, 0.5);
}
/deep/textarea::-moz-input-placeholder {
    /*Mozilla Firefox*/
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 80, 0, 0.5);
}
/deep/textarea::-ms-input-placeholder {
    /*Internet Explorer*/
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 80, 0, 0.5);
}
</style>